import { gql } from 'apollo-boost';
import { DANCER_GRID_FRAGMENT } from '../fragments/dancer-grid-fragment';

export const GET_DANCER_SEARCH_SQL = gql`
  ${DANCER_GRID_FRAGMENT}
  query Dancers(
    $businessId: ID!
    $after: String
    $options: [ID!]
    $skills: [ID!]
    $includeFreeAgents: Boolean
    $nameLike: String
    $isSuspended: Boolean
    $isBlocked: Boolean
    $order: SearchOrder!
    $orderBy: DancerOrder!
    $location: LocationInputObject
    $isAdmin: Boolean!
    $hasLocation: Boolean!
    $overTwentyOne: Boolean!
    $blockedByBusinessId: ID
    $inGroupBusinessId: ID
  ) {
    dancers(
      businessId: $businessId
      profileOptions: $options
      skills: $skills
      includeFreeAgents: $includeFreeAgents
      nameLike: $nameLike
      first: 36
      after: $after
      blocked: $isBlocked
      suspended: $isSuspended
      order: $order
      orderBy: $orderBy
      overTwentyOne: $overTwentyOne
      location: $location
    ) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      nodes {
        ...DancerGridItem
        favorite_clubs {
          id
        }
      }
    }
  }
`;

export const GET_DANCER_ADMIN_SEARCH_SQL = gql`
  ${DANCER_GRID_FRAGMENT}
  query AdminDancers(
    $businessId: ID
    $search: String
    $freeAgent: Boolean
    $deleted: Boolean
    $blockedByBusinessId: ID
    $inGroupBusinessId: ID
    $isAdmin: Boolean!
    $hasLocation: Boolean!
    $first: Int!
    $after: String
  ) {
    admin_dancers(
      businessId: $businessId
      search: $search
      freeAgent: $freeAgent
      deleted: $deleted
      first: $first
      after: $after
    ) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      nodes {
        ...DancerGridItem
      }
    }
  }
`;

export const GET_DANCER_MANAGER_SEARCH_SQL = gql`
  ${DANCER_GRID_FRAGMENT}
  query (
    $businessId: ID!
    $after: String
    $options: [ID!]
    $skills: [ID!]
    $freeAgents: Boolean
    $nameLike: String
    $isBlocked: Boolean
    $order: SearchOrder!
    $orderBy: DancerOrder!
    $location: LocationInputObject
    $isAdmin: Boolean!
    $hasLocation: Boolean!
    $overTwentyOne: Boolean!
    $blockedByBusinessId: ID
    $inGroupBusinessId: ID
  ) {
    manager_dancers(
      businessId: $businessId
      profileOptions: $options
      skills: $skills
      freeAgents: $freeAgents
      nameLike: $nameLike
      first: 36
      after: $after
      blocked: $isBlocked
      order: $order
      orderBy: $orderBy
      overTwentyOne: $overTwentyOne
      location: $location
    ) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      nodes {
        ...DancerGridItem
        favorite_clubs {
          id
        }
      }
    }
  }
`;
