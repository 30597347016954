import { useCallback } from 'react';
import * as PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import { useBusiness } from 'graphql/graph-hooks';
import { useTranslation } from 'react-i18next';

import S from './dancer-search-grid.module.scss';
import * as DancerSearchCTX from '../dancer-search-context';
import { useDancerSearchScroll } from '../use-seach-scroll';
import { removeDancerFromList } from '../services/remove-dancer-from-list';
import { DancerGridItem } from '../../../../common/dancer-grid-item/dancer-grid-item';
import { LoadingDiscoWithContainer } from '../../../../common/loading/loading-disco';
import * as GlobalCTX from '../../../../global-context';
import { logError } from '../../../../../helpers/errors/bug-report';
import {
  GET_DANCER_ADMIN_SEARCH_SQL,
  GET_DANCER_MANAGER_SEARCH_SQL,
} from '../../../../../graphql/queries/dancer-search-query';

const DancerSearchGrid = ({ IS_SEARCH, IS_ADMIN_SEARCH }) => {
  const { t } = useTranslation();
  const { id: currentBusinessId } = useBusiness();
  const { dispatch: globalDispatch } = GlobalCTX.useGlobalCtx();
  const {
    dispatch,
    searchParams,
    state: { selected },
  } = DancerSearchCTX.useDancerSearchCtx(IS_ADMIN_SEARCH);
  const QUERY = IS_ADMIN_SEARCH ? GET_DANCER_ADMIN_SEARCH_SQL : GET_DANCER_MANAGER_SEARCH_SQL;
  const FIELD = IS_ADMIN_SEARCH ? 'admin_dancers' : 'manager_dancers';
  const { pagination, ...variables } = searchParams;
  const { loading, error, data, fetchMore, client } = useQuery(QUERY, {
    fetchPolicy: 'network-only',
    variables,
    onError: (err) => logError(err, IS_ADMIN_SEARCH ? 'GET_DANCER_ADMIN_SEARCH_SQL' : 'GET_DANCER_MANAGER_SEARCH_SQL', DancerSearchGrid.displayName),
    onCompleted: ({ [FIELD]: { pageInfo } }) => {
      globalDispatch(GlobalCTX.setGlobalField('pageSearchParams', searchParams));
      dispatch(
        DancerSearchCTX.setDancerSearchFields({
          selected: [],
          pagination: pageInfo,
        })
      );
    },
  });
  const items = data?.admin_dancers?.nodes || data?.manager_dancers?.nodes;

  const { isLoading, loadMore } = useDancerSearchScroll(fetchMore, FIELD);

  const handleSelectedDancer = useCallback(
    (id) => {
      const item = items.find((item) => item.id == id);
      dispatch(
        DancerSearchCTX.setDancerSearchField(
          'selected',
          !!selected.find((item) => item.id === id) ? selected.filter((item) => item.id !== id) : [...selected, item]
        )
      );
    },
    [dispatch, selected]
  );

  if (loading) {
    return <LoadingDiscoWithContainer />;
  }

  if (error) {
    return <h1 className={S.errorMessage}>{t('errors.generic')}</h1>;
  }

  if (items.length === 0) {
    return <h3 className={S.warningHeading}>{t('dancersPage.no_dancers')}</h3>;
  }

  const handleOnActionSuccess = (id) => {
    dispatch(
      DancerSearchCTX.setDancerSearchField(
        'selected',
        selected.filter((item) => item.id !== id)
      )
    );
    removeDancerFromList(IS_ADMIN_SEARCH, client, id, searchParams);
  };
  return (
    <>
      <div className={S.dancerGrid} onScroll={loadMore}>
        {items.map(({ id }) => {
          return (
            <DancerGridItem
              isScalable
              hasOptions={!searchParams.deleted}
              dancerId={id}
              key={`DANCER_GRID_${id}`}
              hasLocation={!searchParams.isSuspended}
              isSelected={IS_SEARCH ? !!selected.find((item) => item.id == id) : false}
              selectDancer={!IS_ADMIN_SEARCH ? () => handleSelectedDancer(id) : null}
              removeDancer={handleOnActionSuccess.bind(this, id)}
              businessId={IS_ADMIN_SEARCH ? currentBusinessId : searchParams.businessId}
            />
          );
        })}
      </div>

      {isLoading && (
        <div className={S.loadingRow}>
          <LoadingDiscoWithContainer />
        </div>
      )}
    </>
  );
};

DancerSearchGrid.displayName = 'DancerSearchGrid';

DancerSearchGrid.propTypes = {
  IS_SEARCH: PropTypes.bool,
  IS_ADMIN_SEARCH: PropTypes.bool,
};

export { DancerSearchGrid };
